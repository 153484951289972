export var M_CHECKBOXES_REGEX = "\\$MULTIPLE_CHECKBOXES\\$";
export var DFRULES_REGEX = '\\$DFRULES\\$';
export var DFID_TAG = 'DFID_';
export var DFID_VALUE_TAG = '[a-zA-Z1-9_-]{1,}';
export var DFID_REGEX = "\\$".concat(DFID_TAG).concat(DFID_VALUE_TAG, "\\$");
export var POSTAL_CODE_TAG = 'POSTAL_CODE';
export var POSTAL_CODE_VALUE_TAG = "\\(".concat(DFID_VALUE_TAG, ",").concat(DFID_VALUE_TAG, ",").concat(DFID_VALUE_TAG, ",").concat(DFID_VALUE_TAG, ",").concat(DFID_VALUE_TAG, "\\)");
export var POSTAl_CODE_REGEX = "\\$".concat(POSTAL_CODE_TAG, "\\$");
export var AUTO_POSTAL_CODE_REGEX = "\\$".concat(POSTAL_CODE_TAG).concat(POSTAL_CODE_VALUE_TAG, "\\$");
export function hasMCheckboxesTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp("^".concat(M_CHECKBOXES_REGEX))));
}
export function hasDFRulesTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp("^".concat(DFRULES_REGEX))));
}
export function hasPostalCodeTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp(POSTAl_CODE_REGEX)));
}
export function hasAutoPostalCodeTag(name) {
    return Boolean(name === null || name === void 0 ? void 0 : name.match(new RegExp(AUTO_POSTAL_CODE_REGEX)));
}
export function removeAllTag(name) {
    return (name || '').replace(new RegExp([
        '^(',
        [
            DFRULES_REGEX,
            M_CHECKBOXES_REGEX,
            DFID_REGEX,
            POSTAl_CODE_REGEX,
            AUTO_POSTAL_CODE_REGEX,
        ].join('|'),
        '){1,}',
    ].join('')), '');
}
